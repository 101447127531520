import { useEffect } from 'react'
import 'antd/dist/reset.css'
import '../styles/css/main_styles.css'
import { Routes, Route, BrowserRouter as Router } from 'react-router-dom'
import WordsCards from './pages/mainpages/cards/WordsCards'
import Dictionary from './pages/mainpages/dictionary/Dictionary'
import ResetPassword from './pages/specialize/forgotpassword/ResetPassword'
import ChangePassword from './pages/specialize/forgotpassword/ChangePassword'
import DoneWordLists from './pages/mainpages/donewordlists/DoneWordLists'
import Statistic from './pages/mainpages/statistic/Statistic'
import LandingPage from './pages/landingpage/LandingPage'
import NotFound from './pages/specialize/notfound/NotFound'
import FillWord from './pages/mainpages/game/FillWord'
import WebSocket from './utils/WebSocket'
import NewComers from './pages/specialize/newconsomer/NewComers'
import { fetchCheckSession } from '../store/global/extraReducers'
import { errorSelector, hideErrorRoutine, loginLoadingSelector, setLoadingRoutine, userSelector } from '../store/global'
import PrivateRoute from './utils/PrivateRoute'
import ScrollToTop from './utils/ScrollToTop'
import Rules from './pages/mainpages/rules/Rules'
import MainLayout from './blocks/layout/Layout'
import AdminRoute from './utils/AdminRoute'
import AdminPage from './pages/specialize/admin/AdminPage'
import FillGaps from './pages/mainpages/fill-gaps/FillGaps'
import PersonalPolicy from './pages/specialize/personal-policy/PersonalPolicy'
import Loading from './elements/loading/Loading'
import ProfilePage from './pages/mainpages/profilepage/ProfilePage'
import Unsubscribe from './pages/specialize/unsubscribe-email/Unsubscribe'
import ShareList from './pages/specialize/share-list/ShareList'
import AfertaPage from './pages/specialize/aferta/AfertaPage'
import MobileAuth from './pages/mobile-auth/MobileAuth'
import { NavigationPages } from '../common/navigation/navigation-pages'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorPage from './pages/specialize/error/ErrorPage'
import * as API_TELEGRAM from '../shared/http/telegram-notification'
import AuthByCode from './pages/specialize/auth-by-code/AuthByCode'
import PremiumHelp from './pages/specialize/premium-help/PremiumHelp'
import { PremiumCondition } from './pages/specialize/premium/PremiumCondition'
import Terms from './pages/specialize/terms/Terms'
import { Communities } from './pages/mainpages/communities/Communities'
import { LoginByCode } from './pages/specialize/login-by-code/AuthByCode'
import Test from './pages/mainpages/test/Test'
import ThanksPage from './pages/specialize/thanks-page/ThanksPage'
import { Courses } from './pages/mainpages/courses/Courses'
import { CourseOverview } from './pages/mainpages/courses/CoursesOverview/CourseOverview'
import { Course } from './pages/mainpages/courses/Course'
import PersonalDataAgreement from './pages/specialize/personal-data-agreement/PersonalDataAgreement'
import { CreateCourse } from './pages/mainpages/courses/create/CreateCourse'
import { DownLoadApp } from './pages/specialize/download-app/DownLoadApp'
import TeacherLandingPage from './pages/teacher-landing-page/TeacherLandingPage'
import { CreateCourseFull } from './pages/mainpages/courses/edit/CreateCourseFull'
import { getToken } from '../store/memory/memory'
import { useAppDispatch, useAppSelector } from '../store/hooks'

const View = () => {
    const dispatch = useAppDispatch()

    const user = useAppSelector(userSelector)
    const error = useAppSelector(errorSelector)
    const loading = useAppSelector(loginLoadingSelector)

    const checkSession = () => dispatch(fetchCheckSession())
    const hideError = () => dispatch(hideErrorRoutine())
    const setLoading = (l) => dispatch(setLoadingRoutine(l))

    useEffect(() => {
        if (!user && getToken()) {
            checkSession()
        }
        if (!getToken()) {
            //Todo
            checkSession()
        }
    }, [])


    const logErrorToService = (error, info) => {
        API_TELEGRAM.notifyTelegramBot('Caught an error ' + error?.message + ' ' + error?.stack)
        console.error('Caught an error:', error, info)
    }

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <>
                    <Router>
                        <ErrorBoundary onError={logErrorToService} FallbackComponent={ErrorPage}>
                            <ScrollToTop />
                            <Routes>
                                <Route
                                    exact
                                    path={NavigationPages.LANDING}
                                    element={
                                        <LandingPage />}

                                />
                                <Route exact path={`${NavigationPages.PROFILE}`}
                                       element={<PrivateRoute user={user} />}>
                                    <Route
                                        exact
                                        path={`${NavigationPages.PROFILE}/:id?`}
                                        element={
                                            <MainLayout sidebar navbar>
                                                <ProfilePage />
                                            </MainLayout>
                                        }
                                    />
                                </Route>
                                <Route exact path={`${NavigationPages.CARDS}`}
                                       element={<PrivateRoute user={user} />}>
                                    <Route
                                        path={NavigationPages.CARDS}
                                        element={
                                            <MainLayout sidebar navbar>
                                                <WordsCards />
                                            </MainLayout>
                                        }
                                    />
                                </Route>
                                <Route exact path={NavigationPages.TEST}
                                       element={<PrivateRoute user={user} />}>
                                    <Route
                                        user={user}
                                        path={NavigationPages.TEST}
                                        element={
                                            <MainLayout sidebar navbar>
                                                <Test />
                                            </MainLayout>
                                        }
                                    />
                                </Route>
                                <Route exact path={NavigationPages.DICTIONARY}
                                       element={<PrivateRoute user={user} />}>
                                    <Route
                                        path={NavigationPages.DICTIONARY}
                                        element={
                                            <MainLayout sidebar navbar>
                                                <Dictionary />
                                            </MainLayout>
                                        }
                                    />
                                </Route>
                                <Route
                                    path={NavigationPages.DONE_WORDS_LISTS}
                                    element={
                                        <MainLayout sidebar navbar>
                                            <DoneWordLists />
                                        </MainLayout>
                                    }
                                />
                                <Route exact path={NavigationPages.STATISTIC}
                                       element={<PrivateRoute user={user} />}>
                                    <Route
                                        user={user}
                                        path={NavigationPages.STATISTIC}
                                        element={
                                            <MainLayout sidebar navbar>
                                                <Statistic />
                                            </MainLayout>
                                        }
                                    />
                                </Route>
                                <Route exact path={NavigationPages.GUESS_WORD}
                                       element={<PrivateRoute user={user} />}>
                                    <Route
                                        user={user}
                                        path={NavigationPages.GUESS_WORD}
                                        element={
                                            <MainLayout sidebar navbar>
                                                <FillGaps />
                                            </MainLayout>
                                        }
                                    />
                                </Route>

                                {/*Special*/}
                                <Route
                                    exact
                                    path={NavigationPages.RESET_PASSWORD}
                                    element={<ResetPassword />}
                                />
                                <Route
                                    exact
                                    path={NavigationPages.CHANGE_PASSWORD}
                                    element={<ChangePassword />}
                                />
                                <Route
                                    path={NavigationPages.CONFIRM_EMAIL}
                                    element={<NewComers />}
                                />
                                <Route
                                    exact
                                    path={NavigationPages.UNSUBSCRIBE_EMAIL}
                                    element={<Unsubscribe />}
                                />
                                <Route
                                    exact
                                    path={NavigationPages.PERSONAL_POLICY}
                                    element={<PersonalPolicy />}
                                />
                                <Route
                                    exact
                                    path={NavigationPages.PERSONAL_DATA_AGREEMENT}
                                    element={<PersonalDataAgreement />}
                                />
                                <Route
                                    exact
                                    path={NavigationPages.TERMS}
                                    element={<Terms />}
                                />
                                <Route
                                    exact
                                    path={NavigationPages.CREATE_COURSE}
                                    element={<CreateCourse />}
                                />
                                <Route
                                    exact
                                    path={NavigationPages.DOWNLOAD_APP}
                                    element={<DownLoadApp />}
                                />
                                <Route
                                    exact
                                    path={NavigationPages.TEACHERS_LP}
                                    element={<TeacherLandingPage />}
                                />
                                <Route
                                    exact
                                    path={NavigationPages.AFERTA}
                                    element={<AfertaPage />}
                                />
                                <Route
                                    exact
                                    path={NavigationPages.MOBILE_AUTH}
                                    element={<MobileAuth />}
                                />
                                <Route
                                    exact
                                    path={`${NavigationPages.COURSE_EDIT}/:id?`}
                                    element={<CreateCourseFull />}
                                />
                                <Route
                                    path={NavigationPages.LOGIN_BY_CODE}
                                    element={<LoginByCode />}
                                />
                                <Route
                                    exact
                                    path={NavigationPages.COURSES_LIST}
                                    element={
                                        <MainLayout sidebar navbar>
                                            <Courses />
                                        </MainLayout>
                                    }
                                />
                                <Route
                                    exact
                                    path={`${NavigationPages.COURSES_OVERVIEW}/:id?`}
                                    element={
                                        <MainLayout sidebar navbar>
                                            <CourseOverview />
                                        </MainLayout>
                                    }
                                />

                                {/*Special*/}

                                {/*Rules*/}
                                <Route path='/rules/*'
                                       element={
                                           <MainLayout sidebar navbar>
                                               <Rules />
                                           </MainLayout>
                                       } />
                                <Route
                                    exact
                                    path={NavigationPages.PREMIUM}
                                    element={
                                        <MainLayout sidebar navbar>
                                            <PremiumCondition />
                                        </MainLayout>
                                    }
                                />
                                <Route exact path={NavigationPages.PREMIUM_HELP}
                                       element={<PrivateRoute user={user} />}>
                                    <Route
                                        exact
                                        path={NavigationPages.PREMIUM_HELP}
                                        element={
                                            <MainLayout sidebar navbar>
                                                <PremiumHelp />
                                            </MainLayout>
                                        }
                                    />
                                </Route>
                                <Route exact path={`${NavigationPages.THANKS_PAGE}`}
                                       element={<PrivateRoute user={user} />}>
                                    <Route
                                        exact
                                        path={NavigationPages.THANKS_PAGE}
                                        element={
                                            <MainLayout sidebar navbar>
                                                <ThanksPage />
                                            </MainLayout>
                                        }
                                    />
                                </Route>
                                <Route exact path={NavigationPages.FILL_WORDS}
                                       element={<PrivateRoute user={user} />}>
                                    <Route
                                        exact
                                        path={NavigationPages.FILL_WORDS}
                                        element={
                                            <MainLayout sidebar navbar>
                                                <FillWord />
                                            </MainLayout>
                                        }
                                    />
                                </Route>
                                <Route exact path={NavigationPages.COMMUNITIES}
                                       element={<PrivateRoute user={user} />}>
                                    <Route
                                        exact
                                        path={NavigationPages.COMMUNITIES}
                                        element={
                                            <MainLayout sidebar navbar>
                                                <Communities />
                                            </MainLayout>
                                        }
                                    />
                                </Route>
                                <Route exact path={`${NavigationPages.COURSES}`}
                                       element={<PrivateRoute user={user} />}>
                                    <Route
                                        exact
                                        path={`${NavigationPages.COURSES}/:id?`}
                                        element={
                                            <MainLayout sidebar navbar>
                                                <Course />
                                            </MainLayout>
                                        }
                                    />
                                </Route>
                                <Route exact path={NavigationPages.SHARE_LIST}
                                       element={<PrivateRoute user={user} />}>
                                    <Route
                                        path={NavigationPages.SHARE_LIST}
                                        element={
                                            <MainLayout sidebar navbar>
                                                <ShareList />
                                            </MainLayout>
                                        }
                                    />
                                </Route>
                                <Route exact path={NavigationPages.AUTH_BY_CODE}
                                       element={<PrivateRoute user={user} />}>
                                    <Route
                                        path={NavigationPages.AUTH_BY_CODE}
                                        element={
                                            <MainLayout sidebar navbar>
                                                <AuthByCode />
                                            </MainLayout>
                                        }
                                    />
                                </Route>
                                <Route exact path={NavigationPages.ADMIN_PAGE}
                                       element={<AdminRoute user={user} />}>
                                    <Route
                                        path={NavigationPages.ADMIN_PAGE}
                                        element={
                                            <MainLayout sidebar navbar>
                                                <AdminPage />
                                            </MainLayout>
                                        }
                                    />
                                </Route>

                                <Route path='*' element={() => <NotFound />} />
                            </Routes>
                        </ErrorBoundary>
                    </Router>
                    <WebSocket />
                </>
            )}
        </>
    )
}

export default View
