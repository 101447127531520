import '../../../../styles/scss/courses/courses-card.scss'
import { GEButton } from '../../../../common/buttons'
import { goToPageNotPremium } from '../../../utils/goToPage'
import { NavigationPages } from '../../../../common/navigation/navigation-pages'
import { useNavigate } from 'react-router-dom'
import { FC } from 'react'
import { Flex, Tag } from 'antd'
import { SmallCourseDto } from '../../../../shared/types/api'
import { CourseStatus } from '../../../../common/enums/course-status'
import Constants from '../../../../const/Constants'
import PrivilegesUtil from '../../../utils/PrivilegesUtil'
import Privileges from '../../../../const/Privileges'
import { useAppSelector } from '../../../../store/hooks'
import { userSelector } from '../../../../store/global'

interface CoursesCardProps {
    card: SmallCourseDto
    key: number,
}

export const CoursesCard: FC<CoursesCardProps> = ({ card, key }) => {

    const navigate = useNavigate()
    const currentUser = useAppSelector(userSelector)

    const imageUrl = card.headerImage ? `${Constants.queryConst}/courses/${card.headerImage}/image/header` : '../../images/tmp/course1.jpg'

    return (
        <div className='courses-card__container'>
            <Flex justify={'space-between'} align={'center'}>
                <span className='courses-card__card-name'>{card.title}</span>
                {CourseStatus.ACTIVE !== card.status && <Tag color='magenta'>{card.status}</Tag>}
            </Flex>
            <Flex vertical={true} gap='32px' className='courses-card__card-container'>
                <img src={imageUrl} alt={card.title} className='courses-card__img' />
                <span className='courses-card__description'>{card.description}</span>
            </Flex>
            <Flex justify='flex-end' gap={16} wrap={'wrap'}>
                <GEButton
                    onClick={() => goToPageNotPremium(`${NavigationPages.COURSES_OVERVIEW}/${card.id}`, navigate)}>
                    Перейти к курсу
                </GEButton>
                {card.status === 'DRAFT' &&
                <GEButton onClick={() => goToPageNotPremium(`${NavigationPages.COURSE_EDIT}/${card.id}`, navigate)}>
                    Редактировать курс
                </GEButton>}
            </Flex>
        </div>
    )
}