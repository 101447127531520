let Privileges = {
    EDIT_DONE_WORD_LIST: { id: 1, name: 'EDIT_DONE_WORD_LIST', description: 'Может редактировать готовые списки' },
    LONG_TEXT_TRANSLATOR: {
        id: 3,
        name: 'LONG_TEXT_TRANSLATOR',
        description: 'Может добавлять текст для перевода неограниченной длины'
    },
    CHANGE_RULES: { id: 5, name: 'CHANGE_RULES', description: 'Может редактировать правила' },
    CHANGE_DEFAULT_FILES: { id: 4, name: 'CHANGE_DEFAULT_FILES', description: 'Может менять дефолтные картинки' },
    ADMIN: { id: 6, name: 'ADMIN', description: 'Может открывать страницу администратора' },
    FEATURE: { id: 7, name: 'FEATURE', description: 'Привилегии фич' },
    COURSE_MODERATOR: { id: 8, name: 'COURSE_MODERATOR', description: 'Привилегия для администрирования курсов' },


}
export default Privileges
